import { type FC, useState } from "react";
import { InformationContext } from "@/contexts/InformationContext/InformationContext";
import { useLocalStorage } from "usehooks-ts";
import {
    type InformationProviderProps,
    type MockInformationProviderProps,
} from "./InformationProvider.types";
import type {
    InformationContextParams,
    InformationContextTypeType,
} from "@/contexts/InformationContext/InformationContext.types";

export const InformationProvider: FC<InformationProviderProps> = ({
    children,
}) => {
    const [params, setParams, removeParams] =
        useLocalStorage<InformationContextParams>(
            "kozien/information/params",
            null,
        );
    const [type, setType, removeType] =
        useLocalStorage<InformationContextTypeType>(
            "kozien/information/type",
            null,
        );

    const updateParams = (params: InformationContextParams) => {
        setParams(params);

        if (
            params?.returnKey === "measurement" ||
            params?.returnKey === "measurementItem"
        ) {
            setType("measurement");
        } else {
            removeType();
        }
    };

    const clear = () => {
        removeParams();
        removeType();
    };

    return (
        <InformationContext.Provider
            value={{ params, updateParams, clear, type }}
        >
            {children}
        </InformationContext.Provider>
    );
};

export const MockInformationProvider: FC<MockInformationProviderProps> = ({
    params = null,
    children,
}) => {
    const [currentParams, setCurrentParams] =
        useState<InformationContextParams>(params);

    const [type, setType] = useState<InformationContextTypeType>(null);

    const updateParams = (params: InformationContextParams) => {
        setCurrentParams(params);
    };

    const clear = () => {
        setCurrentParams(null);
        setType(null);
    };

    return (
        <InformationContext.Provider
            value={{
                params: currentParams,
                updateParams,
                clear,
                type,
            }}
        >
            {children}
        </InformationContext.Provider>
    );
};
