import { useContext } from "react";
import { InformationContext } from "@/contexts/InformationContext/InformationContext";
import { type InformationContextType } from "@/contexts/InformationContext/InformationContext.types";

export const useInformationContext = (): InformationContextType => {
    const context = useContext(InformationContext);

    if (context === undefined) {
        throw new Error(
            "useInformationContext must be used within a InformationProvider",
        );
    }

    return context;
};
