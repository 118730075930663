import { type StateCreator } from "zustand";
import eq from "fast-deep-equal";
import { nanoid } from "nanoid";
import type { Feature } from "../features";

export type Product = {
    id?: number;
    reference: string;
    productId: string;
    features: Feature[];
    quantity?: number;
    installLocationId?: string;
    previewImage?: string | null;
    remarks?: string | null;
};

export type Reference = Product["reference"];

export type ReferenceOrProduct = Reference | Product;

export interface AddProductInput
    extends Omit<Product, "reference">,
        Partial<Pick<Product, "reference">> {}

type QuoteStateProps = {
    products: Product[];
};

type QuoteStateActions = {
    all: () => Product[];
    productByReference: (reference: Reference) => Product;
    productsWithoutReferenceAndPreviewImage: () => Omit<
        Product,
        "reference" | "previewImage"
    >[];
    productsWithoutReference: () => Omit<Product, "reference">[];
    hasProducts: () => boolean;
    numberOfProducts: () => number;
    quantityOfProducts: () => number;
    add: (product: AddProductInput) => void;
    update: (product: AddProductInput) => void;
    remove: (referenceOrProduct: ReferenceOrProduct) => void;
    count: (referenceOrProduct: ReferenceOrProduct) => void;
    increment: (referenceOrProduct: ReferenceOrProduct) => void;
    decrement: (referenceOrProduct: ReferenceOrProduct) => void;
    setQuantity: (
        referenceOrProduct: ReferenceOrProduct,
        quantity: number,
    ) => void;
    clear: () => void;
};

export type QuoteState = QuoteStateProps & QuoteStateActions;

export const quoteStoreCreator: StateCreator<QuoteState> = (set, get) => ({
    products: [],
    all: () => get().products,
    productByReference: (reference) =>
        get().products.find((p) => String(p.reference) === String(reference))!,
    productsWithoutReferenceAndPreviewImage: () =>
        get().products.map(
            ({
                reference: _reference,
                previewImage: _previewImage,
                ...product
            }) => {
                return product;
            },
        ),
    productsWithoutReference: () =>
        get().products.map(({ reference: _reference, ...product }) => product),
    hasProducts: () => get().products.length > 0,
    numberOfProducts: () => get().products.length,
    quantityOfProducts: () =>
        get().products.reduce((total, { quantity = 1 }) => total + quantity, 0),
    add: ({ reference = nanoid(), quantity = 1, ...product }) => {
        const existingProduct = [...get().all()].find(
            ({ reference: _reference, quantity: _quantity, ...p }) =>
                eq(p, product),
        );

        if (existingProduct)
            return void set((state) => ({
                products: state.products.map((p) =>
                    String(p.reference) === String(existingProduct.reference)
                        ? {
                              ...p,
                              quantity: (p.quantity ?? 1) + quantity,
                          }
                        : p,
                ),
            }));

        return void set((state) => ({
            products: [...state.products, { ...product, reference, quantity }],
        }));
    },
    update: ({ reference = nanoid(), quantity = 1, ...product }) => {
        const existingProduct = [...get().all()].find(
            (p) => String(p.reference) === String(reference),
        );

        if (existingProduct)
            return void set((state) => ({
                products: state.products.map((p) =>
                    String(p.reference) === String(existingProduct.reference)
                        ? {
                              ...p,
                              productId: product.productId,
                              features: product.features,
                              remarks: product.remarks,
                              previewImage: product.previewImage,
                              quantity: (p.quantity ?? 1) + quantity,
                              installLocationId: product.installLocationId,
                          }
                        : p,
                ),
            }));
        return;
    },
    remove: (referenceOrProduct) =>
        void set((state) => ({
            products: state.products.filter(
                (p) =>
                    String(p.reference) !== actualReference(referenceOrProduct),
            ),
        })),
    count: (referenceOrProduct) =>
        [...get().products]
            .filter(
                (p) =>
                    String(p.reference) === actualReference(referenceOrProduct),
            )
            .reduce((total, { quantity = 1 }) => total + quantity, 0),
    increment: (referenceOrProduct) =>
        void set((state) => ({
            products: state.products.map((p) =>
                String(p.reference) === actualReference(referenceOrProduct)
                    ? { ...p, quantity: (p.quantity ?? 1) + 1 }
                    : p,
            ),
        })),
    decrement: (referenceOrProduct) =>
        void set((state) => ({
            products: state.products.map((p) =>
                String(p.reference) === actualReference(referenceOrProduct)
                    ? { ...p, quantity: (p.quantity ?? 1) - 1 }
                    : p,
            ),
        })),
    setQuantity: (referenceOrProduct, quantity) =>
        void set((state) => ({
            products: state.products.map((p) =>
                String(p.reference) === actualReference(referenceOrProduct)
                    ? { ...p, quantity }
                    : p,
            ),
        })),
    clear: () =>
        void set(() => ({
            products: [],
        })),
});

function actualReference(referenceOrProduct: ReferenceOrProduct): Reference {
    return typeof referenceOrProduct === "string" ||
        typeof referenceOrProduct === "number"
        ? String(referenceOrProduct)
        : referenceOrProduct.reference;
}
