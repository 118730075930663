import { SimplePageTemplate } from "@/website/templates/SimplePage/SimplePage";
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    type RouteObject,
} from "react-router-dom";

export const createRouter = (categories: RouteObject[]) => {
    return createBrowserRouter([
        {
            path: "/bestelbon",
            lazy: () => import("@/pages/OrderSlip"),
        },
        {
            path: "/redirect",
            lazy: () => import("@/pages/Redirect"),
        },
        {
            path: "/",
            element: <SimplePageTemplate mainContent={<Outlet />} />,
            children: [
                {
                    path: "/",
                    element: <Navigate to="/products" replace />,
                },
                {
                    path: "/redirect-deal",
                    lazy: () => import("@/pages/RedirectDeal"),
                },
                {
                    path: "/products",
                    lazy: () => import("@/pages/Products"),
                },
                ...categories,
                {
                    path: "/products/:id",
                    lazy: () => import("@/pages/Configurator"),
                },
                {
                    path: "/quote",
                    lazy: () => import("@/pages/QuoteOverview"),
                },
                {
                    path: "*",
                    lazy: () => import("@/pages/Error"),
                },
            ],
        },
    ]);
};
