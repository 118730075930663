import css from "./RedirectBar.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRequestQuoteStore } from "@/store/requestQuote";
import { useTranslation } from "react-i18next";
import InformationBar from "@/website/molecules/InformationBar";
import { useInformationContext } from "@/hooks/useInformationContext/useInformationContext";

export function RedirectBar() {
    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.RedirectBar",
    });
    const { params, type: informationType } = useInformationContext();
    const {
        dealId,
        givenName: firstName,
        familyName: lastName,
        streetAddress: address,
        postalCode: zipCode,
        city,
        reset,
    } = useRequestQuoteStore();

    const handleClick = () => {
        reset();
    };

    if (dealId === "") {
        return null;
    }

    return (
        <InformationBar>
            <strong>{t("prefix")}:</strong>
            <p>{`${firstName} ${lastName},`}</p>
            {address ? <p>{address},</p> : null}
            {zipCode && city ? <p>{`${zipCode} ${city}`}</p> : null}
            {informationType === "measurement" && params?.quoteId ? (
                <p>{t("quoteId", { quoteId: params.quoteId })}</p>
            ) : null}
            {informationType !== "measurement" ? (
                <DeleteIcon
                    data-testid="delete-icon"
                    onClick={handleClick}
                    className={css.icon}
                />
            ) : null}
        </InformationBar>
    );
}
